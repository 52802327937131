var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("iframe", {
    attrs: {
      width: _vm.computedWidth,
      height: _vm.computedHeight,
      src: _vm.videoSrc,
      title: _vm.title,
      frameborder: "0",
      allow:
        "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
      allowfullscreen: ""
    },
    on: { load: _vm.computeSize }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }