var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-table",
    [
      _c(
        "template",
        { slot: "body" },
        _vm._l(_vm.orderedContent, function(content, index) {
          return _c(
            "gov-table-row",
            { key: "content." + index },
            [
              _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                _vm._v(_vm._s(content.label))
              ]),
              _c(
                "gov-table-cell",
                [
                  content.title
                    ? _c("gov-heading", { attrs: { tag: "h2" } }, [
                        _vm._v(_vm._s(content.title))
                      ])
                    : _vm._e(),
                  _vm._l(content.content, function(contentItem, contentIndex) {
                    return _c(
                      "div",
                      { key: "content." + index + "." + contentIndex },
                      [
                        contentItem.type === "copy"
                          ? _c("div", {
                              domProps: {
                                innerHTML: _vm._s(_vm.toHtml(contentItem.value))
                              }
                            })
                          : _vm._e(),
                        contentItem.type === "cta"
                          ? _c(
                              "div",
                              [
                                contentItem.title
                                  ? _c(
                                      "gov-heading",
                                      { attrs: { size: "s", tag: "h3" } },
                                      [_vm._v(_vm._s(contentItem.title))]
                                    )
                                  : _vm._e(),
                                contentItem.description
                                  ? _c("p", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(contentItem.description) +
                                          "\n            "
                                      )
                                    ])
                                  : _vm._e(),
                                _c("gov-button", [
                                  _vm._v(_vm._s(contentItem.buttonText))
                                ]),
                                _c("gov-hint", [
                                  _vm._v("Link to: " + _vm._s(contentItem.url))
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        contentItem.type === "video"
                          ? _c(
                              "div",
                              [
                                contentItem.title
                                  ? _c(
                                      "gov-heading",
                                      { attrs: { size: "s", tag: "h3" } },
                                      [_vm._v(_vm._s(contentItem.title))]
                                    )
                                  : _vm._e(),
                                _c("ck-video-iframe", {
                                  attrs: {
                                    height: "315",
                                    width: "560",
                                    src: contentItem.url,
                                    title: contentItem.title
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        }),
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }